import Vue from 'vue';
import Component from 'vue-class-component';
import store from '@/store';
import { APIBaseRoute, EP } from '@/api-endpoints';
import { Getter } from 'vuex-class';

@Component
export class LogoutMixin extends Vue {
  @Getter('bypassFusionAuth', { namespace: 'features' }) private bypassFusionAuth!: boolean;

  logout(): void {
    store.dispatch('users/logout').then(() => {
      // if fusionauth enabled and access token exists and access_type fusionauth, redirect to user list page
      if (!this.bypassFusionAuth && localStorage.getItem('access_token') && localStorage.getItem('access_type') == 'fusionauth') {
        const access_token = localStorage.getItem('access_token') || '';
        const url = APIBaseRoute(EP.fusionAuth.access_token);      
        window.location.href = url;
      // otherwise login page
      } else {
        window.location.href = "/login";
      }
    });
  }
}
