
import { mixins } from "vue-class-component";
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { Getter, State } from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { Organ } from '@/store/lookups/types';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Allocation, AllocationOfferAction, AllocationOfferResponseCodeValues, AllocationOfferRecipient, AllocationOfferActionRecipient, OfferOutcomeContext, AllocationCompleteAction } from '@/store/allocations/types';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { DeceasedDonor, DeceasedDonorOrganDonations } from "@/store/deceasedDonors/types";
import SelectOtherInput from "../shared/SelectOtherInput.vue";
import TextInput from "../shared/TextInput.vue";
import { IdLookup } from "@/store/validations/types";
import { SaveResult } from "@/types";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";

interface AllocationCompletePageState {
  reasonCategory?: number;
  reason?: number;
  reasonOther?: string;
}

@Component({
  components: {
    SelectInput,
    ModalSection,
    TextAreaInput,
    TextInput,
    CheckboxInput,
    SelectOtherInput
  }
})
export default class AllocationCompleteModal extends mixins(AllocationErrorsMixin, TranslationUtilsMixin, ValidationUtilsMixin) {
  @Prop({ default: null }) donor!: DeceasedDonor; // HTML ID

  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.currentUser) private currentUser!: any;
  @State(state => state.allocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.allocations.isAllocationCompletion) private isAllocationCompletion!: boolean;
  @State(state => state.pageState.currentPage.allocations.allocation_complete) private editState!: AllocationCompletePageState;
  @State(state => state.allocations.organDonationForAllocation) private organDonationForAllocation!: DeceasedDonorOrganDonations;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string;
  @Getter('donorOrganNotUsedCategoriesReasons', { namespace: 'lookups' }) private donorOrganNotUsedCategoriesReasons!: GenericCodeValue[];
  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: Allocation;

  public errorState = false;
  public modalErrorMessage = '';

  /**
   * Return organ donation by allocation.
   *
   * @returns {} 
   */
  get organDonation(): DeceasedDonorOrganDonations|null|undefined {
    return this.organDonationForAllocation;
  }

  /**
   * Return the organ code
   *
   * Get the organ_code param from the url.
   *
   * @returns {string} organ code
   */
  get organCode(): string {
    return this.$route.params?.organ_code?.toString() || '';
  }

  /**
   * Returns if allocation is completed or not
   *
   * @returns {boolean} true if not_used flag is set false otherwise
   */
  get allocationCompleted(): boolean {
    return !!this.organDonation?.not_used;
  }

  /**
   * Return Reason options for the selected category
   *
   * @returns {GenericCodeValue[]} Reason options
   */
  get reasonOptions(): GenericCodeValue[] {
    if (!this.editState?.reasonCategory) {
      return [];
    }
    
    const reasonOptions = this.donorOrganNotUsedCategoriesReasons.find((item: any) => {
      return item.code == this.editState.reasonCategory;
    });

    return reasonOptions?.sub_tables?.organ_not_used_reasons.map((reason: GenericCodeValue) => {
      reason.other_selected = reason.code.toString() === "99";
      return reason;
    });
  }

  /**
   * Populates state with recipient ids and opens the offer modal
   */
  public initialize(): void {
    // clear any error message
    this.modalErrorMessage = '';
    this.errorState = false;
    // build state from valid ids
    this.$store.commit('pageState/set', {
      pageKey: 'allocations',
      componentKey: 'completeAllocation',
      value: this.buildAllocationCompleteState()
    });
    // reset the form
    (this.$refs.completeValidations as any)?.reset();

    this.openModal();
  }

  /**
   * Gets a patch object representing the editState
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing offer details
   */
  public extractPatch(): any {
    if (!this.editState) {
      return {};
    }

    const result: AllocationCompleteAction = {
      not_used: this.allocationCompleted ? false : true,
      not_used_reason_category_code: this.allocation.organ_not_used_reason_category_code?.toString(),
      not_used_reason_code: this.editState.reason,
      not_used_reason_code_other: this.editState.reasonOther,
      selected_not_used_reason_category_code: this.editState.reasonCategory,
      selected_not_used_reason_code: this.editState.reason,
      selected_not_used_reason_code_other: this.editState.reasonOther
    };

    return result;
  }

  // PRIVATE

  /**
   * Return the Allocation complete state
   *
   */
  private buildAllocationCompleteState(): any {
    return {
      not_used: undefined,
      reasonCategory: undefined,
      reason: undefined,
      reasonOther: undefined,
    };
  }

  // Toggle modal
  private openModal(): void {
    (this.$refs.allocationCompleteModal as ModalSection).toggleStaticModal();
  }

  // Close modal
  private closeModal(): void {
    const targetModal = this.$refs.allocationCompleteModal as ModalSection;
    targetModal.hideModal();
  }

  // Clear state values from an array keys
  private clearStateValues(keys: string[]): void {
    keys.forEach(i => Vue.set(this.editState, i, undefined));
  }

  /**
   * Completes local organ donation to avoid reloading the page after completion is succesful.
   *
   */
  public completeOrganDonation(): void {
    if(!this.organDonation){
      return;
    }

    this.organDonation.not_used = this.allocationCompleted;
    this.organDonation.selected_not_used_reason_category_code = this.editState.reasonCategory;
    this.organDonation.selected_not_used_reason_code = this.editState.reason;
    this.organDonation.selected_not_used_reason_code_other = this.editState.reasonOther;
    this.organDonation.not_used_reason_category_code = this.allocation.organ_not_used_reason_category_code === null ? undefined : this.allocation.organ_not_used_reason_category_code ;
    this.organDonation.not_used_reason_code = this.editState.reason;
    this.organDonation.not_used_reason_code_other = this.editState.reasonOther;
  }

  /**
   * Attempt to make an offer they can't refuse
   *
   * Prepares create offer payload for selected Allocation, dispatches create, and handle errors.
   */
  private completeAllocation(): void {
    // Generate payload from editState
    const payload = {
      clientId: this.clientId,
      allocationId: this.allocation._id,
      organCode: this.organCode,
      completionDetails: this.extractPatch()
    };
    // clear any error message
    this.modalErrorMessage = '';
    // Dispatch save action and show response
    this.$store.dispatch('allocations/completeAllocation', payload).then((success: any) => {
      this.initialize();
      this.closeModal();
      this.$emit('reloadAllocation');
      this.$store.dispatch('deceasedDonors/get', this.clientId).then(() => {
        this.$emit('reloadOrganDonation');
      });
      
      this.completeOrganDonation();
    }).catch((error: SaveResult) => {
      this.handleErrorResult(error);
    });
  }

  // Show field-level validation errors from invalid save request
  private handleErrorResult(result: SaveResult): void {
    const idLookup: IdLookup = this.idLookup();

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(result, idLookup);

    const validationObserver = this.$refs.completeValidations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);
  }

    // Map from field-level validation error key to template element IDs
  // Note: right-hand side is an array, in case an error should appear in multiple places
  public idLookup(): IdLookup {
    return {
      "selected_not_used_reason_code_other":     ["selected-not-used-reason-code-other"],
      "selected_not_used_reason_category_code":  ["selected-not-used-reason-category-code"],
      "selected_not_used_reason_code":           ["selected-not-used-reason-code"]
    };
  }
}
