
import { mixins } from "vue-class-component";
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { CtrErrorContext } from '@/types';
import { Getter, State } from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { Organ, OrganSpecification } from '@/store/lookups/types';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Allocation, AllocationRecipient, AllocationOfferAction, AllocationOfferResponseCodeValues, AllocationOfferRecipient, AllocationOfferActionRecipient, OfferOutcomeContext } from '@/store/allocations/types';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";

interface AllocationDiscontinuePageState {
  recipients: AllocationOfferRecipient[];
  type: string;
  reasonCategory?: number;
  reason?: number;
  reAllocatedToNonIntendedRecipient?: boolean;
}

@Component({
  components: {
    SelectInput,
    ModalSection,
    TextAreaInput,
    CheckboxInput,
  }
})
export default class DiscontinueModal extends mixins(AllocationErrorsMixin, TranslationUtilsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.currentUser) private currentUser!: any;
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.allocations.discontinue) private editState!: AllocationDiscontinuePageState;
  @State(state => state.allocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.allocations.isDiscontinuingOneOffer) private isDiscontinuingOneOffer!: boolean;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private donorId!: string;
  @Getter('offerResponses', { namespace: 'lookups' }) private offerResponses!: GenericCodeValue[];
  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: Allocation;
  @Getter('recipientOffer', { namespace: 'allocations' }) private recipientOffer!: AllocationRecipient;
  @Getter('openBackupOffers', { namespace: 'allocations' }) private openBackupOffers!: AllocationRecipient[];
  @Getter('openPrimaryOffers', { namespace: 'allocations' }) private openPrimaryOffers!: AllocationRecipient[];
  @Getter('closedBackupOffers', { namespace: 'allocations' }) private closedBackupOffers!: AllocationRecipient[];
  @Getter('closedPrimaryOffers', { namespace: 'allocations' }) private closedPrimaryOffers!: AllocationRecipient[];
  @Getter('parseCtrErrors', { namespace: 'allocations' }) private parseCtrErrors!: (actions: any[]) => CtrErrorContext[];
  @Getter('getRecipientsByEffectiveRank', { namespace: 'allocations' }) getRecipientsByEffectiveRank!: (recipientRanks?: number[]) => AllocationOfferRecipient[];
  @Getter('ctrIposHeart', { namespace: 'features' }) private ctrIposHeart!: boolean;

  public errorState = false;
  public modalErrorMessage = '';

  /**
   * Get a string representation the organ_code
   *
   * @returns {string} organ_code param as a string
   */
  get organCode(): string {
    return this.$route.params.organ_code ? this.$route.params.organ_code.toString() : '';
  }

  /**
   * Returns the number of open offers for the selected recipients
   *
   * Check all recipient offers for any offer_type_code values
   *
   * @returns {number} number of open offers
   */
  get openOffers(): number {
    if (!this.editState?.recipients) {
      return 0;
    }
    // are there any open primary offers
    const primaryOffers = this.openPrimaryOffers.filter((e: AllocationRecipient) => {
      return this.editState.recipients.find((recipient: AllocationOfferRecipient) => {
        return recipient.effective_rank === e.effective_rank;
      });
    });
    // are there any open backup offers
    const backupOffers = this.openBackupOffers.filter((e: AllocationRecipient) => {
      return this.editState.recipients.find((recipient: AllocationOfferRecipient) => {
        return recipient.effective_rank === e.effective_rank;
      });
    });
    return primaryOffers.length + backupOffers.length;
  }

  /**
   * Returns the number of closed offers for the selected recipients
   *
   * Check all recipient offers for any offer_type_code values
   *
   * @returns {number} number of closed offers
   */
  get closedOffers(): number {
    if (!this.editState?.recipients) {
      return 0;
    }
    // are there any closed primary offers
    const primaryOffers = this.closedPrimaryOffers.filter((e: AllocationRecipient) => {
      return this.editState.recipients.find((recipient: AllocationOfferRecipient) => {
        return recipient.effective_rank === e.effective_rank;
      });
    });
    // are there any closed backup offers
    const backupOffers = this.closedBackupOffers.filter((e: AllocationRecipient) => {
      return this.editState.recipients.find((recipient: AllocationOfferRecipient) => {
        return recipient.effective_rank === e.effective_rank;
      });
    });
    return primaryOffers.length + backupOffers.length;
  }

  /**
   * Compare the number of recipients with the number of open offers
   *
   * A withdraw can only be made on open offers.
   *
   * @returns {boolean} true if we're in a withdraw state
   */
  get isWithdraw(): boolean {
    if (!this.editState?.recipients) {
      return false;
    }
    return this.openOffers === this.editState.recipients.length;
  }

  /**
   * Compare the number of recipients with the number of closed offers
   *
   * A Cancel can only be made on closed offers.
   *
   * @returns {boolean} true if we're in a cancel state
   */
  get isCancel(): boolean {
    if (!this.editState?.recipients) {
      return false;
    }
    return this.closedOffers === this.editState.recipients.length;
  }

  /**
   * Whether or not to show the Re-Allocated to Non-Intended Recipient checkbox.
   *
   * Only applicable if:
   * - Deceased Donor is Out-of-Province (OOP)
   * - Recipient is Highly-Sensitized Patient (HSP) or High Status Heart (HSH)
   *
   * @returns {boolean} true when checkbox is applicable, false otherwise
   */
  get showReAllocatedToNonIntendedRecipient(): boolean {
    // Check Deceased Donor
    const isDonorOop = this.donor?.indicators?.out_of_province;
    if (!isDonorOop) return false;

    // Check all selected Recipient Entries
    // NOTE: this also applies to HSH ranking category (related to IPOS Hearts, see TPGLI-6028)
    const selectedRecipientEntries = this.editState?.recipients || [];
    const recipientEntriesFromCTR = selectedRecipientEntries.filter((recipientEntry: AllocationOfferRecipient) => {
      if (recipientEntry.hsp == 'HSP') return true;
      if (this.ctrIposHeart && recipientEntry.hsh == 'HSH') return true;
      return false;
    });
    return recipientEntriesFromCTR.length === selectedRecipientEntries.length;
  }

  /**
   * Return filtered offer_responses: Withdraw and Cancel
   *
   * @returns {GenericCodeValue[]} Response options
   */
  get responseOptions(): GenericCodeValue[] {
    if (this.offerResponses && this.offerResponses.length <= 0) {
      return [];
    }
    const filteredOfferResponses = this.offerResponses.filter((item: any) => {
      // Only return cancel options if we're in cancel state
      if (this.isCancel) {
        return item.code == AllocationOfferResponseCodeValues.Cancel;
      }
      // Only return withdraw options if we're in withdraw state
      if (this.isWithdraw) {
        return item.code == AllocationOfferResponseCodeValues.Withdraw;
      }
    });
    return filteredOfferResponses;
  }

  /**
   * Return Reason Category options based on selected type
   *
   * Conditionals for Cancel require a further sub set of options
   * based on the organ_code.
   *
   * @returns {GenericCodeValue[]} Reason Category options
   */
  get reasonCategoryOptions(): any {
    // Withdraw
    if (this.editState.type == AllocationOfferResponseCodeValues.Withdraw) {
      const reasonCategoryOptions = this.offerResponses.find((item: any) => {
        return item.code == AllocationOfferResponseCodeValues.Withdraw;
      });
      return reasonCategoryOptions?.sub_tables?.offer_reason_categories;
    }
    // Cancel provides organ specific options
    if (this.editState.type == AllocationOfferResponseCodeValues.Cancel) {
      // Get response type lookup value
      const offerResponseType = this.offerResponses.find((item: any) => {
        return item.code == AllocationOfferResponseCodeValues.Cancel;
      });
      // Get organ specific sub table
      const organReasonCategories = offerResponseType?.sub_tables?.organ_specific_categories_reasons;
      if (!organReasonCategories || organReasonCategories.length <= 0) {
        return [];
      }
      // Get organ specific reason categories
      const organReasonCategoryOptions = offerResponseType?.sub_tables?.organ_specific_categories_reasons.find((item: any) => {
        return item.code == this.organCode;
      });
      return organReasonCategoryOptions.sub_tables.offer_reason_categories;
    }
    return [];
  }

  /**
   * Return Reason options for the selected category
   *
   * @returns {GenericCodeValue[]} Reason options
   */
  get reasonOptions(): any {
    if (!this.editState?.reasonCategory) {
      return [];
    }
    const reasonOptions = this.reasonCategoryOptions.find((item: any) => {
      return item.code == this.editState.reasonCategory;
    });
    return reasonOptions.sub_tables.offer_reasons;
  }

  /**
   * Populates state with recipient ids and opens the offer modal
   */
  public initializeDiscontinueModal(recipientRanks?: number[]): void {
    // clear any error message
    this.modalErrorMessage = '';
    this.errorState = false;
    // Verify the Recipients exist in the Allocation and return AllocationOfferRecipient[]
    const recipients = this.getRecipientsByEffectiveRank(recipientRanks || []);
    // build state from valid ids
    this.$store.commit('pageState/set', {
      pageKey: 'allocations',
      componentKey: 'discontinue',
      value: this.buildDiscontinueState(recipients)
    });
    // reset the form
    (this.$refs.discontinueValidations as any).reset();
    // are we in an error state
    if (!this.isWithdraw && !this.isCancel) {
      this.errorState = true;
      this.modalErrorMessage = 'There was an error with the recipients you selected, please close and try again';
    }
    // do we have recipients
    if (this.editState.recipients.length > 0) {
      this.openModal();
    }
  }

  /**
   * Gets a patch object representing the editState
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing offer details
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.recipients) {
      return {};
    }
    return this.extractDiscontinuePatch(this.editState);
  }

  // PRIVATE

  /**
   * Return the Allocation Discontinue state from recipientIds
   *
   * @param recipientIds array of recpient _ids
   */
  private buildDiscontinueState(recipients?: AllocationOfferRecipient[]): any {
    return {
      recipients: recipients || [],
      type: undefined,
      reasonCategory: undefined,
      reason: undefined,
    };
  }

  // Listen for modal hide event so we can clear the modal
  private modalEvent(options: any) {
    this.$emit("closeModal");
    this.initializeDiscontinueModal();
  }

  // Toggle modal
  private openModal(): void {
    (this.$refs.discontinueModal as ModalSection).toggleStaticModal();
  }

  // Close modal
  private closeModal(): void {
    const targetModal = this.$refs.discontinueModal as ModalSection;
    targetModal.hideModal();
  }

  // Clear state values from an array keys
  private clearStateValues(keys: string[]): void {
    keys.forEach(i => Vue.set(this.editState, i, undefined));
  }

  /**
   * Gets form edit state as an Allocation Discontinue patch
   *
   * Prepare patch for API with Allocation Discontinue details
   *
   * @param discontinue form edit state containing discontinue details
   * @returns {AllocationOfferAction} patch object containing discontinue details
   */
  private extractDiscontinuePatch(discontinue: AllocationDiscontinuePageState): AllocationOfferAction {
    const result: AllocationOfferAction = {
      recipients: discontinue.recipients,
      type: discontinue.type,
      reason_category: discontinue.reasonCategory,
      reason_code: discontinue.reason,
    };

    // Check if we need to handle 'Non-Intended' scenario
    if (this.showReAllocatedToNonIntendedRecipient) {
      // If so, we'll need to flag each recipient entry based on the 'Non-Intended' checkbox
      result.recipients = discontinue.recipients.map((selectedRecipientEntry: AllocationOfferRecipient): AllocationOfferActionRecipient => {
        return {
          ...selectedRecipientEntry,
          reallocated_to_nonintended: !!discontinue.reAllocatedToNonIntendedRecipient,
        };
      });
    }

    return result;
  }

  /**
   * Attempt to make an offer they can't refuse
   *
   * Prepares create offer payload for selected Allocation, dispatches create, and handle errors.
   */
  private discontinueOffer(): void {
    // Before discontinue, provide an alert/confirm dialogue
    const confirmed = confirm(this.$t('DISCONTINUE_DONOR_CONFIRM').toString());
    if (!confirmed) {
      return;
    }
    // Generate payload from editState
    const payload = {
      clientId: this.donorId,
      organCode: this.organCode,
      allocationId: this.allocation._id,
      discontinueDetails: this.extractPatch()
    };
    // clear any error message
    this.modalErrorMessage = '';
    // Dispatch save action and show response
    this.$store.dispatch('allocations/discontinueOffer', payload).then((success: any) => {
      this.initializeDiscontinueModal();
      this.closeModal();
      // emit a reload call
      this.$emit("reload");
      // Do we need to show a secondary warning outcome popup?
      if (this.isOutcomeNotificationRequired(success)) this.displayOutcomeNotification(success);
    }).catch((error: any) => {
      /**
       * this is special check to see if we have very specific CTR errors that are coming back
       * and if so we show more detailed errors.
       * TECH_DEBT: Move this into a central CTR error handling spot
       */
      let errorToShow: any = error;
      if(error.hasOwnProperty('ctr_error_id') && error.hasOwnProperty('ctr_error_message')) {
        if(error.ctr_error_id === 'offerdwl.error.statereasonmismatch' || error.ctr_error_id == 'offerdwl.error.offerhistoryupdate.statemissingreasons') {
          errorToShow = this.$t(error.ctr_error_id, { action_type: this.$t(`action_types.${this.editState.type}`) } );
        }
      }
      this.modalErrorMessage = errorToShow;
    });
  }

  /*
   * Whether or not we need to show the 'outcome notification' popup after saving
   *
   * In general, this is only needed if the offer saved but some sort of secondary
   * issue persists. Usually an error will prevent the offer from saving entirely,
   * but for some situations (e.g. CTR sync error) the offer will be saved to the
   * database as if nothing happened... But there is still an issue that may need
   * user attention, so we close this modal and open another to show a 'warning'.
   *
   * @param response the response payload received from API after posting the offer
   * @returns {boolean} true only if the offer needs an outcome notification
   */
  private isOutcomeNotificationRequired(response: any): boolean {
    // Only show this if we see a CTR sync error in the response
    const ctrErrors = this.parseCtrErrors(response?.data?.offer?.actions || []);
    return ctrErrors.length > 0;
  }

  // Define what is needed for the outcome modal and emit an event
  private displayOutcomeNotification(response: any, isHardStopError?: boolean): void {
    // Outcome warnings are based on CTR Error IDs e.g. attempting to sync HSP offer to CTR
    const ctrErrors = this.parseCtrErrors(response?.data?.offer?.actions || []);
    const warningMessages = ctrErrors.map((warning: CtrErrorContext): string => {
      const warningKey = `warning.${this.allocationIposProgram}.${warning.ctr_error_id}`;
      return this.$te(warningKey) ? this.translateIPOSContext(warningKey).toString() : warning.ctr_error_message;
    });
    // Fetch CTR workflow instructions if there are any
    const instructionsTemplates = ctrErrors.map((ctrError: CtrErrorContext): string => {
      const instructionsKey = `instructions.${this.allocationIposProgram}.${ctrError.ctr_error_id}`;
      return this.$te(instructionsKey) ? this.$t(instructionsKey).toString() : this.$t('instructions.generic').toString();
    });

    const context: OfferOutcomeContext = {
      actionId: 'discontinue_offer',
      ctrErrors,
      warningMessages,
      instructionsTemplates,
      isHardStopError,
    };

    this.$emit('display-outcome-notification', context);
  }
}
