import { render, staticRenderFns } from "./DeclineTermsOfServiceModal.vue?vue&type=template&id=7df40047&"
import script from "./DeclineTermsOfServiceModal.vue?vue&type=script&lang=ts&"
export * from "./DeclineTermsOfServiceModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/shared/_locales/TermsOfServiceModal.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fshared%2FDeclineTermsOfServiceModal.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports