
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DateUtilsMixin} from "@/mixins/date-utils-mixin";
import {isMasked} from "@/utils";
import SubSection from "@/components/shared/SubSection.vue";
import {Getter} from "vuex-class";
import {RecipientsSearchResult} from "@/store/recipients/types";
import {TableConfig} from "@/types";
import { alphabeticRegexPattern, numericalRegexPattern, nationalRecipientRegexPattern } from '@/store/utilities/types';

@Component({
  components: {
    SubSection
  }
})
export default class OopRecipientsSearchResults extends mixins(DateUtilsMixin) {

  @Getter('showList', { namespace: 'recipients' }) public oop_recipients!: RecipientsSearchResult;
  @Prop({required: true}) searchedTerm!: string;
  loading = false;
  pageSize = 25;
  currentPage = 1;

  get recipientsColumns(): any[] {
    return [
      {
        label: `${this.$t('ctr_id')}`,
        field: 'national_recipient_id',
        sortable: false,
        filterOptions: {
          enabled: false,
        }
      },
      {
        label: `${this.$t('client_id')}`,
        field: 'client_id',
        sortable: false,
        filterOptions: {
          enabled: false,
        }
      },
      {
        label: `${this.$t('first_name')}`,
        field: 'first_name',
        sortable: false,
        filterOptions: {
          enabled: false,
        }
      },
      {
        label: `${this.$t('last_name')}`,
        field: 'last_name',
        sortable: false,
        filterOptions: {
          enabled: false,
        }
      },
      {
        label: `${this.$t('dob')}`,
        field: 'dob',
        type: 'date',
        sortable: false,
        width: '130px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
        formatFn: this.formatDate
      },
      {
        label: `${this.$t('insurance_plan')}`,
        field: 'insurance_plan',
        sortable: false,
        filterOptions: {
          enabled: false,
        }
      },
      {
        label: `${this.$t('insurance_number')}`,
        field: 'insurance_number',
        thClass: 'has-style',
        tdClass: 'has-style',
        width: '160px',
        sortable: false,
        filterOptions: {
          enabled: false,
        }
      }
    ];
  }
  formatDate(value: string): string|undefined {
    return isMasked(value) ? value : this.parseDisplayDateUi(value);
  }

  mounted() {
    if(this.searchedTerm) {
      this.searchRecipients();
    }
  }

  searchRecipients() {
    this.loading = true;
    const searchCriteria: any = {
      search_strategy: 'or',
      sort_type: 'asc'
    };
    let fieldsToSearch: string[] = [];

    const alphabeticRegex = new RegExp(alphabeticRegexPattern);
    const numericalRegex = new RegExp(numericalRegexPattern);
    const nationalRecipientRegex = new RegExp(nationalRecipientRegexPattern);

    if (nationalRecipientRegex.test(this.searchedTerm)) {
      searchCriteria['sort_by'] = 'national_recipient_id';
      fieldsToSearch = ['national_recipient_id'];
    } else if (alphabeticRegex.test(this.searchedTerm)) {
      fieldsToSearch = ['first_name', 'last_name'];
    } else if (numericalRegex.test(this.searchedTerm)) {
      fieldsToSearch = ['partial_client_id'];
    }

    fieldsToSearch.forEach(k => {
      searchCriteria[k] = this.searchedTerm;
    });
    const mappedParams: string[] = [];
    Object.keys(searchCriteria).forEach((k: any) => mappedParams.push(`${k}=${searchCriteria[k]}`));

    const dispatchMethod = 'recipients/getListOop';
    this.$store.dispatch(
      dispatchMethod,
      {
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        search_params: `&override_filter=true&${mappedParams.join('&')}`,
        oopResults: true
      }
    ).then(() => {
      this.loading = false;
    });
  }

  get oopRecipientTableConfig(): TableConfig {
    return {
      data: this.oop_recipients.entries,
      columns: this.recipientsColumns,
      empty: 'Cannot find any Recipients that match the selected filters',
      createButton: false,
      pagination: true,
      sortOptions: {
        enabled: true
      },
      paginationOptions: {
        enabled: true,
        perPage: this.pageSize,
        mode: 'pages',
        perPageDropdown: [10, 25, 100],
        setCurrentPage: this.currentPage,
        dropdownAllowAll: false,
        nextLabel: 'Older',
        prevLabel: 'Newer',
        rowsPerPageLabel: 'Results per page',
        position: 'bottom'
      }
    };
  }

  public updatePagination(event: any) {
    this.currentPage = event.currentPage;
    this.pageSize = event.currentPerPage;

    this.searchRecipients();
  }

  public selectRecipient(event: any) {
    if (event.row.client_id !== undefined) {
      const clientId: string = event.row.client_id.toString();
      const routerUrl = 'edit-recipient-oop';
      this.$router.push({
        name: routerUrl,
        params: {
          id: clientId,
        }
      });
    } else {
      console.warn('Missing recipient clientId');
      this.$router.push({name: 'list-recipients-oop'});
    }
  }

}
