
import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import store from '@/store';
import i18n from '@/i18n';
import { mixins } from "vue-class-component";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { LogoutMixin } from "@/mixins/logout-mixin";
import { APIBaseRoute, EP } from '@/api-endpoints';

interface RoleChoice {
  code?: string,
  value?: string
}

@Component({
  components: {
    ModalSection,
    SelectInput
  }
})
export default class UserMenu extends mixins(TranslationUtilsMixin, LogoutMixin) {
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;
  @Getter('getHelpUrl', { namespace: 'features' }) private getHelpUrl!: string;

  public preventClose(event: any): void {
    event.stopPropagation();
  }

  setLocale(locale :string): void {
    if (locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
      // force page refresh to rebuild lookup store
      window.location.reload();
    }
  }

  get localeOptions(): any {
    return this.getAvailableLocales.map((lang: string) => {
      return { code: lang, value: this.$t(lang) };
    });
  }

  get getCurrentLocale(): string|undefined {
    if (!this.$i18n.locale) { return undefined; }
    const options = this.localeOptions || [];
    const current = options.find((t: any) => t.code === this.$i18n.locale).value;
    return `${this.$t('language')} ${current}`;
  }
}
